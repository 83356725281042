.backTop {
    display: none;
    bottom: 125px;
    position: fixed;
    z-index: 9998;
    right: 15px;
    z-index: 2;
    .icon {
      background: url(__('/img/template-images/btn_back_to_top.png'));
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      height: 2.5rem;
      width: 2.5rem;
      @include media-breakpoint-up(lg) {
        height: 2.5rem;
        width: 2.5rem;
      }
    }
    &:hover,
    &:focus {
      .icon {
        //background: url(__('/img/template-images/back-top-hover.svg'));
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
      }
    }
  }