.obiInteractive,
.obiInteractive-device {
    width: 254px;

    margin: 0 auto;
    padding: 0;

    position: relative;

    @include media-breakpoint-up(lg) {
        width: 282px;
    }
}

.obiInteractive-device,
.obiInteractive-device img {
    pointer-events: none;
}

.obiInteractive-hotspots {
    list-style: none;
    margin: 0;
    padding: 0;
}

.obiInteractive-hotspots .anchor {
    cursor: pointer;
    appearance: none;
    background: url(__('/img/content-images/icons/interact-plus.png'));
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    border: none;
    border-radius: 100%;
    width: 38px;
    height: 38px;
    position: absolute;
    transform-origin: center;

    &.is-active {
        background: url(__('/img/content-images/icons/interact-minus.png'));
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }

    @include media-breakpoint-up(lg) {
        width: 42px;
        height: 42px;
    }
}

.obiInteractive-hotspots .content {
    text-align: center;

    opacity: 0;
    
    position: absolute;

    p {
        font-size: 17px;
        line-height: 23px;
        letter-spacing: 0;

        margin: 0;
        padding: 0;

        > strong {

            font-size: 20px;
            font-weight: 700;
            line-height: 23px;
            letter-spacing: 0;

            display: block;

            margin: 25px 0 5px 0;
        }
    }

    &.is-active {
        opacity: 1;
    }

    @include media-breakpoint-up(lg) {
        width: 437px;
        height: auto;

        transform-origin: center;

        p {
            > strong {
                display: inline-block;
                
                margin: 0 0px 0 0;
            }
        }
    }
}


.obiInteractive-hotspots li {
    &:nth-child(1) .anchor {
        top: 229px;
        left: 53px;
    }
    &:nth-child(2) .anchor {
        top: 176px;
        left: 31px;
    }
    &:nth-child(3) .anchor {
        top: 61px;
        left: 108px;
    }
    &:nth-child(4) .anchor {
        top: 61px;
        left: 175px;
    }
    &:nth-child(5) .anchor {
        top: 111px;
        left: 185px;
    }
    @include media-breakpoint-up(lg) {
        &:nth-child(1) .anchor {
            top: 254px;
            left: 64px;
        }
        &:nth-child(2) .anchor {
            top: 195px;
            left: 34px;
        }
        &:nth-child(3) .anchor {
            top: 68px;
            left: 120px;
        }
        &:nth-child(4) .anchor {
            top: 68px;
            left: 194px;
        }
        &:nth-child(5) .anchor {
            top: 123px;
            left: 206px;
        }
    }
}

.obiInteractive-mobile-spacer {
    height: 0px;
    @include media-breakpoint-up(lg) {
        height: 0 !important;
    }
}