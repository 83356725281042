// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Set up a decent box model on the root element
 */
 html {
  box-sizing: border-box;
}
body {
	margin:0;
	padding:0;
	font-family: $font-family-base;
  font-size: $font-size-base;
  font-weight: $font-weight-base;
  line-height: $line-height-base;
  color: #444444;
  //line-height: 1.25;
}

p, ol, ul, h6 {
  // font-size: 1.125rem;
  // @include media-breakpoint-up(lg){
  //   font-size: 1.25rem;
  //   line-height: 1.3;
  // }
}

p, h6 {
  margin-bottom: 0.75rem;
}

h1, h2, h3, h4, h5, h6 {
  line-height: 1.25;
}

h1 {
  font-size: $font-mobile-size-h1;
  line-height: 1.875rem;
  @include media-breakpoint-up(md){
    font-size: $font-size-h1;
    line-height: 3.125rem;
  }
}

h2 {
  font-size: $font-mobile-size-h2;
  font-weight: 400;
  line-height: 1.875rem;
  @include media-breakpoint-up(md){
    font-size: $font-size-h2;
    line-height: 2.8125rem;
  }
}
h3 {
  font-size: $font-mobile-size-h3;
  font-weight: 400;
  line-height: 1.875rem;
  @include media-breakpoint-up(md){
    font-size: $font-size-h3;
    line-height: 2.1875rem;
  }
}
h4 {
  font-size: $font-mobile-size-h4;
  font-weight: 400;
  line-height: 1.5625rem;
  @include media-breakpoint-up(md){
    font-size: $font-size-h4;
    line-height: 1.875rem;
  }
}
h5 {
  font-size: $font-mobile-size-h5;
  font-weight: 500;
  @include media-breakpoint-up(md){
    font-size: $font-size-h5;
  }
}
h6 {
  font-size: $font-mobile-size-h6;
  @include media-breakpoint-up(md){
    font-size: $font-size-h6;
  }
}

body,
html {
   height: 100%;
}

img {
  max-width: 100%;
}

sub, sup {
  font-size: 65%;
}

a {
  color: $colorBrandBlue;
  //text-decoration: underline;
  &:hover {
    color: $colorBrandTeal;
    text-decoration: none;
  }
}

hr {
  border-top: 1px solid $colorGray;
  &.hr--secondary {
    border-top-color: #D0D0D0;
  }
}

ul {
  padding-left: 1.25rem;
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*, *::before, *::after {
  box-sizing: inherit;
} 


ul ul {
  list-style: disc;
}


@media print {
  .notifyTray.is-sticky {
    display: none!important;
  }
  .well {
    max-height: none !important;
  }
  .accordionBody {
    padding-bottom: 1.5rem !important;
    max-height: 15000 !important;
    height: auto !important;
    opacity: 1 !important;
  }
 
}