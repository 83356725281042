// stylelint-disable declaration-no-important

// Contextual backgrounds

@mixin bg-variant($parent, $color) {
  #{$parent} {
    background-color: $color !important;
  }
  a#{$parent} {
    @include hover-focus {
      background-color: darken($color, 10%) !important;
    }
  }
}
