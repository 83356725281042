.obiHelps {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;

    margin: 0;
    padding: 0;

    @include media-breakpoint-up(lg) {
        margin: 85px 0;
    }
}

.obiHelps-item {
    display: flex;
    flex: 0 0 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-wrap: nowrap;

    .item-icon {
        flex: 0 0 87px;
        width: 87px;
        height: 87px;

        order: -1;

        @include media-breakpoint-up(lg) {
            flex: 0 0 144px;
            width: 144px;
            height: 144px;

            order: unset;
        }
    }

    .item-text {
        font-size: 17px;
        font-weight: 400;
        line-height: 23px;
        letter-spacing: 0;

        flex: 0 0 100%;

        margin: 16px 0 32px 0;
        padding: 0;

        strong {
            color: #056CB6;

            font-weight: 700;

            @include media-breakpoint-up(lg) {
                font-size: 20px;
            }
        }

        @include media-breakpoint-up(lg) {
            flex: 1 1 auto;

            margin: 0;
            padding: 0;
        }
    }

    @include media-breakpoint-up(lg) {
        flex-direction: row;
        
        &:nth-child(1) {
            flex: 0 0 40%;
        }
        
        &:nth-child(2) {
            flex: 0 0 40%;
        }
    
        &:nth-child(3) {
            flex: 0 0 33.333%;
        }
        
        &:nth-child(4) {
            flex: 0 0 auto;
            img {
                width: 205px;
            }
        }
        
        &:nth-child(5) {
            flex: 0 0 33.333%;
        }
    
        &:nth-child(6) {
            flex: 0 0 40%;
        }

        &:nth-child(7) {
            flex: 0 0 40%;
        }
    }
}