.footnote {
    @include font-size(14px);
    line-height: 1.25rem;
		margin-bottom: .4rem;
		&:last-child {
			margin-bottom: 0;
    }
    @include media-breakpoint-up(md) { 
      @include font-size(14px);
      line-height: normal;
    }
}

.fake-bullet {
  padding-left: 21px;
  position: relative;
  margin-left: -19px;
  @include media-breakpoint-up(md) {
    padding-left: 0;
    margin-left: 0;
  }
  .bullet {
    position: absolute;
    left: 0;
    text-align: right;
    top: 4px;
    width: 21px;
    @include media-breakpoint-up(md) {
      left: -21px;
    }
  }
  span.bullet {
    top: 0;
  }
}