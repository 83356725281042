// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------
.footerMain {
    position: relative;
    padding-bottom: $spacer*2;
    padding-top: $spacer*2;
    font-size: $footerMain-size;
    color: $footerMain-color;
    background: $footerMain-background;
    font-family: $headings-font-family;
    @include media-breakpoint-up(lg) {
      font-weight: 700;
    }
 }
 .footerMain-topLink {
   display: flex;
   align-items: center;
   justify-content: center;
   padding-top: $spacer/2;
   padding-bottom: $spacer/2;
   text-align: center;
   text-decoration: none;
   text-transform: uppercase;
   color: $footerMain-topLink-color;
   background: $footerMain-topLink-background;
   font-weight: bold;
   &:hover, &:active  {
     text-decoration: none;
     color: $footerMain-topLink-hover-color;
   }
   &:focus {
     //color: $footerMain-topLink-color;
   }
 }
 
 .footerMain-inner {
    max-width: $maxWidth;
    margin-right: auto;
    margin-left: auto;
    padding-right: 15px;
    padding-left: 15px;
 }
 
 .footerMain-logo {
    padding-bottom: $spacer*.75;
    width: 160px;
    margin-bottom: 1.75rem;;
 
    .footerMain-logoLink {
       display: block;
       font-weight: normal;
       margin: 0;

    }
 }
 
 .footerMain-nav {
   
   list-style: none;
   margin: 0 0 1.75rem 0;
   padding: 0;
   @include media-breakpoint-up(md) {
    display: flex;
    flex-flow: row wrap;
   }
 }
 
 .footerMain-navItem {
    display: block;
    font-size: 17px;
    font-weight: 400;
    margin-bottom: 1.75rem;
    
 
    @include media-breakpoint-up(sm) {
      
    }
    @include media-breakpoint-up(md) {
      display: inline-block;
      margin-right: 1rem;
      font-size: 14px;
      margin-bottom: 0;;
    }
 
    // &::after {
    //  content: " | ";
    //  display: inline-block;
    //  padding-left: 0.5rem;
    //  padding-right: 0.5rem;
    // }
 
    &:last-child{
      margin-right: 0;
    }
 }
 
 .footerMain-navLink {
    color: $footerMain-navLink-color;
    display: inline-block;
    text-decoration: none;
 
    &:hover {
     color: $colorBrandBlueLighter;
     text-decoration: none;
    }
 }
 
 .footerMain-topLinkIcon {
    margin-left: $spacer/2;
    color: $colorBrandOrange;
    font-size: 1.5rem;
 }
 
 .footerMain-collateral {
   font-weight: normal;
   @include font-size(12px);
 
   @include media-breakpoint-up(lg){
     @include font-size(14px);
   }
 
    p {

    }
 
    &.copy {
     @include media-breakpoint-up(lg){

     }
    }
 }
 