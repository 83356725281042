.heroMain {
    position: relative;
    justify-content: center;
    overflow: hidden;
    background: #3177b8; /* Old browsers */
	background: -moz-linear-gradient(left,  #3177b8 0%, #83c8c1 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(left,  #3177b8 0%,#83c8c1 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to right,  #3177b8 0%,#83c8c1 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3177b8', endColorstr='#83c8c1',GradientType=1 ); /* IE6-9 */
    @include media-breakpoint-up(md){
        display: flex;
    }
    &:after {
        content: '';
        display: none;
        @include media-breakpoint-up(md){
            display: block;
        }
    }
    &-container {
        @include media-breakpoint-up(md){
            position: absolute;
            height: 100%;
            left: 0;
            right: 0;
        }
    }
    .heroMain-container {
        @include media-breakpoint-down(sm) {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
    }
    .heroMain-containedBg-img {
        width: 100%;
        max-width: none;
        overflow: auto;
        margin: auto;
        position: absolute;
        @include media-breakpoint-up(md){
            left: 50%;
            height: 100%;
            width: auto;
            -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
        }
        @include media-breakpoint-up(lg){
            //left: 50%;
            //width: 100%;
            //height: auto;
            //max-width: 2200px;
        }
        @include media-breakpoint-up(xl){
            //width: 100%;
        }
    }
    .heroMain-outerBox {
        background:none;
        padding-left: 0;
        padding-right: 0;
        position: relative;
        z-index: 2;
        padding-top: 65%;
        @include media-breakpoint-up(sm){
            padding-top: 60%;
        }
        @include media-breakpoint-up(md){
            //padding: 1rem;
            padding-top: 0;
            position: absolute;
            top:0;
            right: 0;
            width: 52%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
        @include media-breakpoint-up(lg){
            width: 60%;
        }
    }
    .heroMain-innerBox {
        padding: 1rem;
        position: relative;
        color: $white;
        text-align: center;
        @include media-breakpoint-up(md){
            text-align: left;
        }
    }
    
}


.heroMain-containedBg {
    z-index: 1;
    height: 100%;
}


.heroMain--home {
    &:after {
        padding-bottom: 44%;
        @include media-breakpoint-up(md){
            padding-bottom: 59%;
            max-width: 600px;
        } 
        @include media-breakpoint-up(lg){
            padding-bottom: 40%;
            max-width: 600px;
        }  
        @include media-breakpoint-up(xl){
            padding-bottom: 40%;
        }  
        @media (min-width: 2200px) {
            padding-bottom: 680px;
        }
    }
    .heroMain-containedBg-img {
        
        @include media-breakpoint-up(md){
            left: 60%;
            //min-height:480px;
        }
        @include media-breakpoint-up(lg){
            left: 50%;
            width: 100%;
            height: auto;
            max-width: 2200px;
        }
        @include media-breakpoint-up(xl){
            left: 50%;
            width: 100%;
        }
    }
    .heroMain-innerBox {
        text-align: center;
        @include media-breakpoint-up(md){
            text-align: left;
        }
    }
    .heroMain-innerBox2 {
        position: relative;
        color: $white;
        padding: 1rem;
        &:after {
            content: '';
            background: $colorBrandBlue;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            @include media-breakpoint-up(md){
                opacity: 0.8;
            }
        }

    }


}

.heroMain--homeSpanish {
    &:after { 
        @include media-breakpoint-up(xl){
            padding-bottom: 33%;
        }  

    }
}



// hero content
.heroMain-title1 {
    font-size: 1.5625rem;
    line-height: 1.875rem;
    font-weight: 500;
    @include media-breakpoint-up(xl){
        font-size: 2.4375rem;
        line-height: 3.25rem;
    }
}

.heroMain-title2 {
    font-size: 1.25rem;
    line-height: 1.5625rem;
    font-weight: 500;
    @include media-breakpoint-up(xl){
        font-size: 1.875rem;
        line-height: 2.1875rem;
    }
}

//for sub heros
.heroMain-title2 {

}

.heroMain-mediaTitle {
    font-size: 1.25rem;
    font-weight: 500;
    @include media-breakpoint-up(xl){
        font-size: 1.875rem;
        line-height: 2.3125rem;
        margin-bottom: 1rem;;
    }
}

.heroMain-mediaBtn {
    font-size: 0.9375rem;
    font-weight: bold;
    position: relative;
    color: $white;
    margin-bottom: 0;
    transition: all 0.5s ease;
    &:before {
        content: '';
        width: 100%;
        height: 100%;
        background: $white;
        opacity: 0.25;
        position: absolute;
        top: 0;
        left: 0;
    }
    &:hover {
        color: $white;
        &:before {
            opacity: 0.35;
        }
    }
}


.heroCta {
    z-index: 2;
    position: relative;
    -title {
        font-size: 1.25rem;
        font-weight: 100;
        @include media-breakpoint-up(md){
            font-size: 1.5625rem;
        }
    }
}

.heroCta-image {
    width: 130px;
    margin-bottom: 1rem;
    @include media-breakpoint-up(md){
        position: absolute;
        width: 160px;
        top: -60px;
    }
}

.heroCta-body {
    h3 { font-weight: 200;}
    @include media-breakpoint-up(md){
        margin-left: 180px;
    }
}


.heroMain--secondary {
    &:after {
        padding-bottom: 44%;
        @include media-breakpoint-up(md){
            padding-bottom: 350px;
        } 
        @include media-breakpoint-up(lg){
            padding-bottom: 450px;
        }  
        @include media-breakpoint-up(xl){
            padding-bottom: 550px;
        }  
        @media (min-width: 1780px) {
            padding-bottom: 680px;
        }
    }
    .heroMain-containedBg-img {
        @include media-breakpoint-up(xl){
            //left: 50%;
            //width: 100%;
            height: 100%;
            //max-width: 2200px;
        }
    }
    .heroMain-outerBox {
        @include media-breakpoint-up(md){
            width: 60%;
            left: 0;
        }
        @include media-breakpoint-up(lg){
            // size props
            width: 100%;
            max-width: calculateRem(678px);
            // position props
            right: auto;
            left: 0;
        }
        &.w-463 {
            @include media-breakpoint-up(lg){
                max-width: calculateRem(463px);
            }
        }
        &.w-495 {
            @include media-breakpoint-up(lg){
                max-width: calculateRem(495px);
            }
        }
        &.w-560 {
            @include media-breakpoint-up(lg){
                max-width: calculateRem(560px);
            }
        }
        &.w-610 {
            @include media-breakpoint-up(lg){
                max-width: calculateRem(610px);
            }
        }
        &.w-614 {
            @include media-breakpoint-up(lg){
                max-width: calculateRem(614px);
            }
        }
        &.w-622 {
            @include media-breakpoint-up(lg){
                max-width: calculateRem(622px);
            }
        }
        &.w-678 {
            @include media-breakpoint-up(lg){
                max-width: calculateRem(678px);
            }
        }
        &.w-541 {
            @include media-breakpoint-up(lg){
                max-width: calculateRem(541px);
            }
        }
        
        
    }
    .heroMain-innerBox--background {
        // style props
        background: rgba(#056CB6, 0.8021); //TODO: GR - globalize this blue color
        border-radius: 0;

        // spacing props
        //margin: calculateRem(50px) 0 0 0;
        padding: calculateRem(10px) calculateRem(13px) calculateRem(16px);

        // NOTE: GR - remove margin bottom and padding bottom from last element
        > *:last-child {
            // spacing props
            margin-bottom: 0;
            padding-bottom: 0;
        }

        @include media-breakpoint-up(md) {
            // style props
            border-radius: calculateRem(8px);

            // spacing props
            padding: calculateRem(29px) calculateRem(32px);
        }
    }
}