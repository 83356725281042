// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------

//loop through each color in the $palettes map in style/scss/_variables.scss and add the modifiers
@each $palette in $palettes {
    //Grab/Define the main colors e.g. "colorBrandBlue"
    $baseColorName: nth($palette, 1);
    //Grab/Define the modifiers, e.g. "lighter"
    $modifiers: nth($palette, 2);
//Generate helper classes
@each $name, $value in $modifiers {
  //when we find the name "base" we want to remove it from the helper classes
  // i.e. good = ".colorBrandBlue", bad = ".colorBrandBlueBase"
    @if $name == base {$name : '';}
    //Generate text color classes
    .#{$baseColorName}#{capitalize($name)} {
      color: $value !important;
    }
    //Generate text color classes
    .fill#{capitalize($baseColorName)}#{capitalize($name)} {
      background-color: $value !important;
      &, a:not(.btn), a:not(.btn):hover {
          color: color-contrast($value);
      }
    }
  }
}

.contain-some {
  @media (min-width: 768px) {
    max-width: 730px;
  }
  @media (min-width: 992px) {
    max-width: 1010px;
  }
}

.contain-more {
  @media (min-width: 768px) {
    max-width: 700px;
  }
  @media (min-width: 992px) {
    max-width: 959px;
  }
}

.contain-most {
  @media (min-width: 768px) {
    max-width: 670px;
  }
  @media (min-width: 992px) {
    max-width: 802px;
  }
}

.bg-blueDark {
  background:  $colorBrandBlueDark;
  color: $white;
}

.bg-mystic {
  background:  $colorMystic;
}

.bg-blueLight {
  background:  $colorBrandBlueLight;
}

.bg-blueLighter {
  background:  $colorBrandBlueLighter;
}

.bg-flower {
  position: relative;
  overflow: hidden;
  &:before {
    position: absolute;
    right: 0%;
    top: -40px;
    content: "";
    background: url(__('/img/content-images/aa360-background-half.png')) no-repeat;
    background-position-x: right;
    background-position-y: -300px;
    background-size: 700px;
    width: 100%;
    height: 1220px;
    overflow: hidden;
  }
}

.relative {
  position: relative;
}

//random font sizes
.font-22 {
  font-size: 1.375rem;
}
.font-16 {
  font-size: 1rem;
}

.font-30-20 {
  font-size: 1.875rem;
  line-height: normal;
  @include media-breakpoint-down(md) {
    font-size: 1.25rem;
    line-height: normal;
  }
}

.font-mobile-14 {
  @include media-breakpoint-down(md) {
    font-size: 0.875rem;
    line-height: normal;
  }
}

.font-mobile-15 {
  @include media-breakpoint-down(md) {
    font-size: 0.9375rem;
    line-height: normal;
  }
}

.font-mobile-17 {
  @include media-breakpoint-down(md) {
    font-size: 1.0675rem;
    line-height: normal;
  }
}

.font-mobile-20 {
  @include media-breakpoint-down(md) {
    font-size: 1.25rem;
    line-height: normal;
  }
}

//color font
.color-blue {
  color: $colorBrandBlue;
}
.color-blue2 {
  color: $colorBrandBlue2;
}
.color-teal {
  color: $colorBrandTeal;
}
.color-teal2 {
  color: $colorBrandTeal2;
}
.color-orange {
  color: $colorBrandOrange;

}

.color-white {
  color: $white;
}

a.color-white {
  &:hover {
    color: $white;
  }
}
//Borders
.border-md-left {
  @include media-breakpoint-up(md) {
    border-left: 1px solid $colorBorder;
  }
}

.outline {
  border: 2px solid $colorBrandBlue;
  padding: 0.5rem;
  border-radius: 6px;
}

.radius-6 {
  border-radius: 6px;
}

.w-100 {
  width: 100%;
}
.w-60 {
  width: 60px;
}
.w-84 {
  width: 84px;
}
.w-129 {
  width: 129px;
}
.w-142 {
  width: 142px;
}
.w-159 {
  width: 159px;
}
.w-160 {
  width: 160px;
}
.w-171 {
  width: 171px;
}
.w-185 {
  width: 185px;
}
.w-213 {
  width: 213px;
}

.w-220 {
  width: 220px;
}
.w-234 {
  width: 234px;
}
.w-250 {
  width: 250px;
}
.w-300 {
  width: 300px;
}
.w-md-450 {
  @include media-breakpoint-up(md) {
    width: 450px;
  }
}

.w-md-352 {
  @include media-breakpoint-up(md) {
    width: 352px;
  }
}

.w-md-517 {
  @include media-breakpoint-up(md) {
    width: 517px;
  }
}
.w-max-517 {
  max-width: 517px;
}

.underline {
  text-decoration: underline;
}

.strong-mobile {
  @include media-breakpoint-down(lg) {
    font-weight: bolder;
  }
}

.ws-normal {
  white-space: normal;
}

.noScroll {
  overflow: hidden;
  height: 100%;
}
.noUnderline {
  text-decoration:none !important;
}

.white-space-normal {
  white-space: normal;
}

.font-weight-600 {
  font-weight: 600;
}

.lh-1-75 {
  line-height: 1.75;
}