// -----------------------------------------------------------------------------
// This file contains all styles related to global notify message.
// -----------------------------------------------------------------------------
.notifyTray {
   padding-bottom: $spacer*3;
   transition: height .3s ease-in-out;
   background: $colorMystic;

   @include font-size(14px);
   .notifyTray-content {
     padding-right: 1rem;
     position: relative;

     @include media-breakpoint-up(sm) {
       padding-top: 5px;
     }
     @include media-breakpoint-up(lg) {
     }
   }

   .notifyTray-inner  {
     padding-top: $spacer*3;
     @include media-breakpoint-up(lg) {
      padding-top: $spacer*1.5;
     }
   }

   a {
      text-decoration: none;
      color: $colorBrandBlue;
      text-decoration: underline;
      &:hover, &:active {
        color: $colorBrandBlueDark;
      }
   }

   h3 {
     //display: inline;
     font-weight: 500;
     color: $colorBrandBlue;
     @include font-size(25px);
   }
   h6 {
     font-weight: bold;
     color: $colorBrandBlue;
     //@include font-size(16px);
     margin-bottom: 0.25rem;;
   }
   p {
     margin-bottom: 1rem;
   }
   ul {
     list-style: none;
     padding-left: .35rem;
     li {
       margin-bottom: 0.35rem;
     }
     li:before {
       content: "•";
       color: $colorBrandGray;
       display: inline-block;
       width: .5rem;
       margin-left: -.5rem;
       margin-right: 0;
     }

     strong { color: $colorBrandBlue; }
   }

   &.is-sticky {
      position: fixed;
      z-index: 300;
      right: 0;
      bottom: 0;
      left: 0;
      box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
      //overflow: hidden;
      height: 95px;
      padding: 0;
      line-height: 1.4;
      background: $colorWhite;
      //border-top: 1px solid $colorBrandBlueDark;
      @include media-breakpoint-up(lg) {
        height: 110px;
      }
      h3 {
         line-height: normal;
         font-weight: 600;
      }
      h3, h6, li, .mb-3 {
        margin-bottom: 0 !important;

      }
      strong {
        font-weight: 400;
        color: $colorBrandBlue;
      }
      h6 {
        font-weight: 400;
        font-size: 1.0625rem;
      }
      .notifyTray-content {
        h3 {
          font-size: 1.0625rem;
          margin-bottom: 3px;
        }
        p, li, h4 {
          font-size: 1.0625rem;
          margin-bottom: 3px;
          font-weight: 400;;
        }
      }
      
      .notifyTray-inner {
         //overflow: hidden;
         max-width: $maxWidth;
         max-height: 100%;
         margin-right: auto;
         margin-left: auto;
         padding: $spacer*.125 0 0 0;
      }
      &:not(.full) {
        h3 {
        }
        .notifyTray-content {

        }
      }
   }
   &.full {
      overflow: scroll;
      -webkit-overflow-scrolling: touch;
      .notifyTray-inner {
         overflow: visible;
         max-height: none;
      }
      h3 {
        font-size: 1.125rem;
      }
   }
}

.notifyTray-toggle {
   display: none;
   .is-sticky & {
      display: block;
      text-align: right;
      position: absolute;
      right: 0;
      top: 4px;
   }
}
.notifyTray-toggleLink {
   font-size: 0.8125rem;
   color: $colorBrandBlue;
    position: relative;
    cursor: pointer;
    white-space: nowrap;
    font-weight: bold;
    
   &:hover, &:active  {
      color: $notifyTray-toggle-hover-color;
      @include media-breakpoint-down(sm) {
        &:before {
          //color: $colorBrandBlueDark;
        }
      }
   }
   @include media-breakpoint-up(sm) {
    padding-right: 2rem;
    &::before {
      border: $colorBrandBlue 1px solid;
      width: 22px;
      height: 22px;
      content: '';
      border-radius: 50%;
      position: absolute;
      right: 0;;
    }
    &:after {
      content: '';
      width: 0;
      height: 0;
      border: solid $colorBrandBlue;
      border-width: 0 1px 1px 0;
      display: inline-block;
      padding: 0.28rem;
      display: inline-block;
      transform: rotate(45deg);
      position: absolute;
      right: 0.38rem;
      top: 50%;
      margin-top: -4px;
      @include media-breakpoint-up(lg) {
        margin-top: -6px;
      }
  }
   }
   
   .is-sticky & {
      //@extend .fontello-plus;
      //padding-left: $spacer;
      //cursor: pointer;
   }
   .is-sticky.full & {
      
   }
   span {
      display: flex;
      flex-flow: column-reverse wrap;
      position: relative;
      @include font-size(12px);
      justify-content: center;
      @include media-breakpoint-up(lg) {
        flex-flow: row wrap;
        @include font-size(14px);
      }
   }
}
.is-sticky.full {
  .notifyTray-toggleLink {
    &:after {
      transform: rotate(0-135deg);
      top: 13px;
    }
  }
}
/*
.notifyTray-top {
  position: absolute;
  right: 0;
  bottom: 10px;
  font-size: 2rem;
  box-shadow: -2px 5px 15px -2px rgba(0,0,0,0.27);
  text-align: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: none;
  svg {
    color: $colorBrandBlueDark;
  }
}
*/
.notifyTray.full {
  .notifyTray-top {
    display: block;
  }
}

.notifyTray-content {
  font-family: $headings-font-family;
  ul, p {
    color: $colorGray;
  }
}
/*
.notifyTray-toggleLink:after {
    content: '';
    position: absolute;
    right: 50%;
    top: -28px;
    width: 20px;
    height: 20px;
    background: #fff;
    transform: rotate(36.5deg) skewX(-20deg) translateX(50%);
    box-shadow: -1px -1px 0 #005677;
}
.full .notifyTray-toggleLink:after {
    top: -29px;
    box-shadow: 1px 1px 0 #005677;
}
*/
.notifyTray.is-sticky .borderBlue {
    border-top: 1px solid $colorBrandBlue;
}

.notifyTray-content-title {
  @include media-breakpoint-down(sm){
    font-size: 1rem;
    margin-bottom: 0;
  }
}

.notifyTray .notifyTray-content-subtitle {
  @include media-breakpoint-down(sm){
    font-size: 1rem;
    margin-bottom:3px;
  }
}

.notifyTray.is-sticky {
  .notifyTray-content-title {
    margin-bottom: 0;
  }
}