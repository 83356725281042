// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------
.headerMain {
	position: relative;
	z-index: 10;
	background: #3177b8;
	/* Old browsers */
	background: -moz-linear-gradient(left, #3177b8 0%, #83c8c1 100%);
	/* FF3.6-15 */
	background: -webkit-linear-gradient(left, #3177b8 0%, #83c8c1 100%);
	/* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to right, #3177b8 0%, #83c8c1 100%);
	/* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#3177b8', endColorstr='#83c8c1', GradientType=1);
	/* IE6-9 */

	@include media-breakpoint-up(lg) {
		transition: all .5s;

		.header-sticky & {
			position: fixed;
			z-index: 300;
			top: 0;
			right: 0;
			left: 0;
			animation: fadein .5s;

			.headerMain-util {
				display: none;
			}
		}
	}
}

.headerMain-util {
	background: $colorMystic;
}

.headerMain-utilInner {
	display: flex;
	flex-wrap: wrap;
	max-width: $maxWidth;
	margin-right: auto;
	margin-left: auto;
	position: relative;
	justify-content: space-between;
	flex-direction: column-reverse;

	@include media-breakpoint-up(lg) {
		flex-direction: row;
	}

	@include media-breakpoint-up(lg) {
		padding-right: 15px;
		padding-left: 15px;
	}
}

.headerMain-exitAlert {
	background: $colorBrandBlue;
	color: $white;
	padding: 10px;
	position:relative;
	z-index:1;
	flex-direction:column;
	display:none;
	&:after {
		content:'';
		position:absolute;
		top:0;
		bottom:0;
		left:50%;
		width:100vw;
		transform:translateX(-50%);
		z-index:-1;
		background-color:inherit;
	}
	&.is-open {
		display:flex;
	}

	@include media-breakpoint-up(md) {
		flex-direction:row;
		align-items:center;
	}
}
.headerMain-exitAlertBlurb {
	margin-bottom:10px;
	@include media-breakpoint-up(md) {
		padding-right:30px;
		margin-bottom:0;
	}
}

.headerMain-exitAlertActions {
	>*:last-child {
		margin-bottom: 0;
	}

	@include media-breakpoint-up(md) {
		flex: 0 0 25%;
	}
}

.headerMain-exitAlertbtn {
	background: $white;
	display: block;
	text-align: center;
	padding: calculateRem(14px) calculateRem(20px);
	margin-bottom: 1rem;

	&--cancel {

		&,
		&:hover {
			background-color: $colorBrandBlueDark;
			color: $white;
		}
	}
}

.headerMain-navUtil {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	padding-left: 0;
	margin-bottom: 0;
	width: 100%;
	justify-content: space-evenly;
	text-align: center;

	@include media-breakpoint-up(lg) {
		flex-wrap: nowrap;
		margin-bottom: 0;
		padding: 0;
		list-style: none;
		font-size: $headerMain-navUtil-size;
	}
}

.headerMain-navUtilItem {
	list-style: none;
	flex: 1;
	border-left: 1px solid $white;

	&:first-child {
		border-left: 0;

		.headerMain-navUtilLink {
			border-left: none;

		}
	}

	@include media-breakpoint-up(lg) {
		width: auto;
		flex-basis: auto;
		border: none;
		display: block;
	}
}

.headerMain-navUtilLink {
	display: block;
	cursor: pointer;
	margin: 8px 0 8px;
	padding: 0 4px 0 4px;
	text-decoration: none;
	color: $colorGray;
	font-size: 0.825rem;
	font-weight: 500;
	line-height: 1rem;
	text-align: center;
	margin-left: auto;
	margin-right: auto;
	cursor: pointer;

	@include media-breakpoint-up(lg) {
		font-size: 1rem;
		padding: 0 10px 0 10px;
	}

	&:hover,
	&:active {
		text-decoration: none;
		color: $colorBrandBlue;
		//color: $headerMain-navUtilLink-active-color;
	}

	@include media-breakpoint-up(lg) {
		align-items: center;
		font-size: 0.875rem;
	}

	@include media-breakpoint-up(lg) {
		flex: 0 1 auto;
	}
}


.headerMain-navToggle {
	margin: 0 $spacer 0 0;
	padding: 0;
	border: 0;
	z-index: 9;
	background: none;
	position: relative;

	&:after {
		content: '';
		width: 40px;
		height: 40px;
		border: 1px solid $white;
		border-radius: 50%;
		display: block;
		position: absolute;
		top: 16px;
		left: -8px;
	}

	@include media-breakpoint-up(xl) {
		display: none;
	}

	&:hover {
		background-color: transparent;
	}

	&:focus {
		outline: 0;
	}

	.headerMain-navToggle-holder {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 1.5rem;
		height: 1.5rem;
		padding: 0;
		border: 0;
	}


	&.is-active {
		.headerMain-navToggle-holder {
			span {

				&:first-child {
					transform: translateY(0.125rem) rotate(-45deg);
					animation: navToggle3 0.25s linear;
				}

				@keyframes navToggle3 {
					0% {
						transform: translateY(0);
					}

					50% {
						transform: translateY(0);
					}

					100% {
						transform: translateY(0.125rem) rotate(-45deg);
					}
				}

				&:nth-child(2) {
					width: 0;
					transition: width 0.25s linear;
				}

				&:last-child {
					transform: translateY(-0.125rem) rotate(45deg);
					animation: navToggle4 0.25s linear;
				}

				@keyframes navToggle4 {
					0% {
						transform: translateY(0);
					}

					50% {
						transform: translateY(0);
					}

					100% {
						transform: translateY(-0.125rem) rotate(45deg);
					}
				}
			}

		}


	}

	.headerMain-navToggle-holder {
		span {
			width: 1.75rem;
			height: 0.125rem;
			background-color: $white;
			transition: 0.25s ease;

			&:first-child {
				transform: translateY(-0.35rem) rotate(0deg);
				animation: navToggle1 0.25s linear;
			}

			@keyframes navToggle1 {
				0% {
					transform: translateY(0);
				}

				50% {
					transform: translateY(0);
				}

				100% {
					transform: translateY(-0.35rem) rotate(0deg);
				}
			}

			&:last-child {
				transform: translateY(0.35rem) rotate(0deg);
				animation: navToggle2 0.25s linear;
			}

			@keyframes navToggle2 {
				0% {
					transform: translateY(0);
				}

				50% {
					transform: translateY(0);
				}

				100% {
					transform: translateY(0.35rem) rotate(0deg);
				}
			}
		}
	}
}

.headerMain-logoLink {
	display: block;
	padding: .75rem 1rem 1rem 0.9375rem;

	@include media-breakpoint-up(xl) {
		padding: .75rem 0.25rem 1rem 0;
	}
}

.headerMain-logoImg {
	width: 223px;
}
.headerMain-logo {
	@include media-breakpoint-up(xl) {
		width: 230px;
	}
}

.headerMain-nav {
	//position: fixed;
	//top: 0;
	//left: -100vw;
	//display: flex;
	//align-items: center;
	//justify-content: center;
	width: 100%;
	height: 0;
	transition: 0.25s linear;
	z-index: 3;
	overflow: hidden;

	@include media-breakpoint-up(xl) {
		position: static;
		top: auto;
		left: auto;
		width: 100%;
		height: auto;
		background-color: transparent;
		//min-height: 63px;
		// flex-direction: column;
		// justify-content: flex-start;
		//padding-top: 6px;

	}

	&.is-open {
		height: auto;
	}
}

.headerMain-mainInner {
	max-width: $maxWidth;
	margin-right: auto;
	margin-left: auto;

	//position: relative;
	@include media-breakpoint-up(xl) {
		padding-right: 15px;
		padding-left: 15px;
	}
}

.headerMain-navMain {
	display: flex;
	flex-direction: column;
	list-style: none;
	margin: 0;
	padding: 0;
	width: 100%;
	//margin-top: 69px;
	//overflow-y: auto;

	@include media-breakpoint-up(xl) {
		align-items: flex-end;
		flex-direction: row;
		margin-top: 0;
		justify-content: space-between;
		padding-left: 1%;
		overflow-y: initial;

	}
}

.headerMain-navLink-holder {
	//flex: 1;
	position: relative;
	border-bottom: 1px solid $white;

	@include media-breakpoint-up(xl) {
		position: static;
		padding: 0 15px;
		text-align: right;
		border-bottom: none;

	}

	&:hover {
		.headerMain-navSub {
			@include media-breakpoint-up(xl) {
				display: block;

			}
		}
	}

	&.is-open {
		.headerMain-navSub {
			@include media-breakpoint-down(xl) {
				display: block;

			}
		}

		.headerMain-navLink {
			border-color: $white;
			cursor: pointer;

		}
	}

}

.headerMain-navLink-holder-special {
	position: absolute;
	bottom: -36px;
	right: 0;
	z-index: 11;

	@include media-breakpoint-up(xl) {
		bottom: 80px;
	}
}

.headerMain-navLink {
	position: relative;
	display: inline-block;
	text-align: left;
	padding: 1rem 4rem 1rem 0.9375rem;
	color: $white;
	font-size: 1.0625rem;
	font-weight: 600;
	letter-spacing: normal;
	line-height: 1.25rem;
	transition: all 0.5s ease;
	width: 100%;
	cursor: pointer;

	&:after {}

	&:hover,
	&.is-active {
		cursor: pointer;
		color: $white;
		text-decoration: none;
		cursor: pointer;

		&:after {
			content: '';
			position: absolute;
			margin: 0 auto;
			top: 0;
			left: 0;
			right: 0;
			width: 100%;
			background: #fff;
			height: 100%;
			opacity: 0.3;

			@include media-breakpoint-up(xl) {
				display: none;
			}
		}

	}

	@include media-breakpoint-up(xl) {
		border: none;
		background: none;
		color: $white;
		padding: .75rem 0rem 1.5rem 0rem;
		font-size: 1.0625rem;

		&:after {
			display: none;
		}

		&:before {
			content: '';
			position: absolute;
			margin: 0 auto;
			left: 0;
			right: 0;
			width: 0;
			background: #fff;
			-webkit-transition-duration: 0.5s;
			-moz-transition-duration: 0.5s;
			transition-duration: 0.5s;
			-o-transition-duration: 0.5s;
			-ms-transition-duration: 0.5s;
			opacity: 0.0;
			height: 2px;
			margin-bottom: 12px;
			width: 100%;
			opacity: 0.25;
			background: #fff !important;
			border-radius: 4px;
		}

		&:before {
			bottom: 0rem;
		}

		/*&:hover,*/
		&.is-active {
			color: $white;
			text-decoration: none;

			&:before {
				opacity: 1;
				height: 4px;

			}
		}
	}

}

.headerMain-navLink-special {
	cursor: pointer;
	background: $colorBrandOrange;
	padding: 0.5rem 0.75rem 0.5rem 0.75rem;
	font-weight: 600;
	color: $white;
	font-size: 0.9375rem;
	display: block;
	transition: all 0.5s ease;

	&:hover {
		color: $white;
		background: $colorBrandBlue;
		text-decoration: none;
	}

	&:after {
		content: '';
		border: solid $white;
		border-width: 0 2px 2px 0;
		display: inline-block;
		padding: 4px;
		transform: rotate(-45deg);
		margin-left: 0.5rem;

	}
}

.headerMain-navSub {
	display: none;
	width: 100%;
	left: 0;
	list-style: none;
	padding: 0rem 0 0rem 2.1875rem;
	//border-bottom: 1px solid $colorBrandBlue;
	-webkit-transition-duration: 0.5s;
	-moz-transition-duration: 0.5s;
	transition-duration: 0.5s;
	-o-transition-duration: 0.5s;
	-ms-transition-duration: 0.5s;

	@include media-breakpoint-up(xl) {
		background: $white;
		padding-top: 10px;
		position: absolute;
		text-align: center;
		padding: .5rem 1rem;
		z-index: 3;
	}

	&.is-active {
		@include media-breakpoint-up(xl) {
			display: block;
		}
	}

	li {

		@include media-breakpoint-up(xl) {
			display: inline-block;
			margin-left: 34px;

			&:first-child {
				margin-left: 0;
			}
		}
	}

}

.headerMain-navSubTrigger {
	position: absolute;
	right: 0;
	padding: 0 15px;
	top: 0;
	color: $white;
	font-size: 2.5rem;
	font-weight: 100;
	line-height: 2.5rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
}

.headerMain-navSubLink-holder {

	@include media-breakpoint-up(xl) {
		display: flex;
		justify-content: center;
	}
}


.headerMain-navSubLink {
	display: block;
	text-align: left;
	padding: 0 1rem;
	color: $white;
	font-size: 1.0625rem;
	font-weight: 600;
	border-top: 1px solid $white;
	padding: 1rem 0;

	&:hover {
		color: $white;
	}

	@include media-breakpoint-up(xl) {
		color: $colorBrandBlue;
		padding: 0.25rem 1.25rem;
		font-size: 0.875rem;

		&:hover {
			color: $colorBrandBlueDark;
			text-decoration: none;
		}
	}

	h6 {
		font-weight: bold;
		font-size: 1rem;
	}

}

.trigger-minus,
.trigger-plus {
	cursor: pointer;

	&:hover {
		color: $colorBrandBlueDark;
	}
}

.trigger-minus {
	display: none;
}

.headerMain-navLink-holder.is-open {
	.trigger-plus {
		display: none;
	}

	.trigger-minus {
		display: inline;
	}
}


.headerMain--espanol {
	.headerMain-navLink-holder {
		padding: 0 2px;
	}
}


.headerMain-navUtilSub {
	display: none;
	position: absolute;
	background: $colorMystic;
	z-index: 99;
}
.headerMain-navUtilSubLink {
	display: block;
	text-align: left;
	padding: 0.45rem 0.85rem;
	border-bottom: 1px solid $colorBrandBlueDark;
	&:last-child {
		border-bottom: none;
	}
	
}

.headerMain-navUtilItem {
	//position: relative;
	&:hover {
		.headerMain-navUtilSub {
			display: block;
		}
	}
}