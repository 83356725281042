// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------

.btn {
    @include font-size(15px);
    font-weight: bold;
    line-height: 1;
    
    padding: calculateRem(14px) calculateRem(20px);
}
.btn-primary {
    background: $colorBrandBlue;
    border-color: $colorBrandBlue;
    &:hover {
        background: $colorBrandOrange;
        border-color: $colorBrandOrange; 
    }
}

.btn-secondary {
    background: $colorBrandOrange;
    border-color: $colorBrandOrange;
    &:hover {
        background: $colorBrandBlue;
        border-color: $colorBrandBlue;
    }
}

.btn-arrow {
    &:after {
		content: '';
		border: solid $white;
		border-width: 0 2px 2px 0;
		display: inline-block;
		padding: 4px;
		transform: rotate(-45deg);
		margin-left: 0.5rem;

	}
}

.btn-arrow--absolute {
    position: relative;
    &:after {
        position: absolute;
        right: 10px;
        top: 50%;
        margin-top: -7px;
        border-width: 0 2px 2px 0;
        padding: 6px;
	}
}