.accordions {

}
.accordionBody {
    padding-top: 0;
    padding-bottom: 0;
    max-height: 0;
    height: 0;
    opacity: 0;
    transition: all 0.5s ease;
    overflow: hidden;
}

.accordionBody.is-active {
    //padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    max-height: 15000px;
    height: auto;
    opacity: 1;
}

.accordionTrigger {
    //border-top: 1px solid $colorBorder;
    cursor: pointer;
    position: relative;
    .container {
        position: relative;
    }
    
}
.accordionTriggerBtn {
    width: 46px;
    height: 46px;
    background: url(__("/img/template-images/icon-plus-blue.svg"));
    background-repeat: no-repeat;
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -19px;
}
.accordionTriggerCollapse {
    display: none;
}

.is-active {
    .accordionTriggerBtn {
        background: url(__("/img/template-images/icon-minus-blue.svg"));
        background-repeat: no-repeat;
    }
    .accordionTriggerExpand {
        display: none;
    }
    .accordionTriggerCollapse {
        display: inline-block;
    }
}