.toolsAndResources {
    @include brand-gradient;
}

.toolsAndResources-title {
    color: $white;

    font-size: 30px;
    font-weight: 400;
    line-height: 35px;
    letter-spacing: 0;
    text-align: center;

    margin: 0 0 41px 0;
}

.toolsAndResources-item {
    background: rgba($white, 0.1538);
    border-radius: 6px;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;

    padding: 19px 8px;
    margin: 0;

    width: 100%;
    height: 100%;

    transition: background 0.333s ease-in-out;

    .item-icon,
    .item-text,
    .item-link {
        display: block;

        margin: 0 8px;
        padding: 0;

        line-height: 1;
        letter-spacing: 0;
    }

    .item-icon {
        flex: 0 0 64px;
        width: 64px;
        height: 64px;
    }

    .item-text {
        color: $white;

        display: block;

        flex: 1 1 auto;

        p {
            font-weight: 400;
            font-size: 17px;
            line-height: 23px;
            letter-spacing: 0;
        }

        >*:last-child {
            margin-bottom: 0;
        }
    }

    .item-link {
        display: none;

        @include media-breakpoint-up(lg) {
            display: block;
            
            flex: 0 0 44px; 
            width: 44px;
            height: 44px;
        }
    }

    &:hover {
        background: rgba($white, 0.26);

        text-decoration: none;
    }
}

.toolsAndResources-foot {
    color: $white;

    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0px;

    margin: 0;
    padding: 0;
}