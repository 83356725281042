.intro {
    // NOTE: GR - remove margin bottom and padding bottom from last element
    .introBody > *:last-child {
        // spacing props
        margin-bottom: 0;
        padding-bottom: 0;
    }
}

.intro.introSecondary {
    .introTitle {
        // style props
        color: #056CB6; // TODO: GR - globalize this blue color

        // font props
        font-size: 25px; // px fallback
        font-size: calculateRem(25px);
        line-height: calculateRem(30px);
        font-weight: 400;
        letter-spacing: 0px;

        // spacing props
        margin: 0 0 calculateRem(22px) 0;
        padding: 0;
        
        @include media-breakpoint-up(md) {
            // font props
            font-size: 40px; // px fallback
            font-size: calculateRem(40px);
            line-height: calculateRem(35px);

            // spacing props
            margin: 0 0 calculateRem(30px) 0;
            padding: 0;
        }
    }
    .introBody {
        // spacing props
        padding: 0 20px;
    }
}