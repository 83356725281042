.section-title {
    color: $colorBrandBlue;
    // gets size from H2
    // font-size: 40px;
    // font-weight: 400;
    // line-height: 52px;
    letter-spacing: 0;
    margin: 0 0 45px 0;
}

.section-subTitle {
    color: $colorBrandBlue;
    // gets size from H3
}

.section-lead {
    // Gets size from
    // font-size: 17px;
    // font-weight: 400;
    // line-height: 23px;
    // letter-spacing: 0;
    margin: 0 0 30px 0;
    padding: 0;
}


//also .footnote
.section-body {
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0;
    text-align: center;

    margin: 0;
    padding: 0;
}