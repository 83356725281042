.form-control {
    background: $white;
    border-color: $white;
    border-width: 2px;
    box-shadow: none;

    font-size: 17px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0;

    min-height: 44px;

    &::placeholder {
        color: $colorBrandBlueAlt;
        font-size: 17px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0;
    }

    &[type="date"]::-webkit-datetime-edit {
        color: $colorBrandBlueAlt;
    }

    &[type="date"]:before {
        content: attr(placeholder);
        color: red;
        &:focus,
        &:valid {
            content: ""
        }
    }
    option{
        color: $colorBrandGray;
    }
}
select.form-control[data-chosen=""]{
    color: $colorBrandBlueAlt;
}
.form-radio {
    input[type="radio"] {
        display: none;
    }
    label {
        cursor: pointer;

        display: flex;
        align-items: flex-start;
        justify-content: stretch;
        flex-direction: row;
        flex-wrap: nowrap;
        
        &:before {
            content: '';

            background: $white;
            border: 2px solid $white;
            border-radius: 100%;
            
            display: block;
            flex: 0 0 24px;

            margin: 0 16px 0 0;
            padding: 0;
            
            width: 24px;
            height: 24px;
        }
    }
    input[type="radio"]:checked + label {
        cursor: default;

        &:before {
            background: $colorBrandBlueAlt;
            border-width: 6px;
        }
    }
    &.has-error {
        label {
            color: $colorBrandError;
            &:before {
                border-color: $colorBrandError;
            }
        }
        input[type="radio"]:checked + label {
            color: initial;
            &:before {
                background: $colorBrandBlueAlt;
                border-width: 6px;
                border-color: $white;
            }
        }
        
        
    }
}

.form-group {
    &.has-error {
        .form-control {
            border-color: $colorBrandError;
            &::placeholder {
                color: $colorBrandError;
            }
        }
        .text-danger {
            color: $colorBrandError;
        }
        .form-text {
            display: none;
        }
        iframe{
            border-color: $colorBrandError;
            border-width: 2px;
            border-style: solid;
            border-radius: 3px;
        }
    }
}