.media {

}

.media--vertMobile, .media--vertMobile.flex-md-row-reverse {
    flex-direction: column;
    align-items: center;
    @include media-breakpoint-up(md) {
        flex-direction: row;
    }
}

a.media {
    color: #444444;
}