.well {
    background: $white;
    border-radius: 8px;
    border: 1px solid $white;
    
    width: 100%;
    height: auto;
    max-height: 210px;
    
    padding: 16px;
    
    overflow-y: auto;

    > * {
       margin-bottom: 8px;
    }

    > ul {
        li {
            margin-bottom: 8px;
        }
    }
}